import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AwardsComponent } from './pages/awards/awards.component';
import { ArticleComponent } from './pages/blog/article/article.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BrandsAndAgenciesComponent } from './pages/brands-and-agencies/brands-and-agencies.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ThankYouComponent } from './pages/contact-thank-you/contact-thank-you.component';
import { FeaturesComponent } from './pages/features/features.component';
import { HomeComponent } from './pages/home/home.component';
import { InfluenceForGoodComponent } from './pages/influence-for-good/influence-for-good.component';
import { InfluencersComponent } from './pages/influencers/influencers.component';
import { ServicesComponent } from './pages/services/services.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';

const routes: Routes = [
  { path: 'influencer-marketing-platform', component: FeaturesComponent },
  { path: 'features', redirectTo: 'influencer-marketing-platform', pathMatch: 'full' },
  { path: 'influencer-marketing-services', component: ServicesComponent },
  { path: 'services', redirectTo: 'influencer-marketing-services', pathMatch: 'full' },
  { path: 'brands-and-agencies', component: BrandsAndAgenciesComponent },
  { path: 'influencer-platform-for-creators', component: InfluencersComponent },
  { path: 'influencers', redirectTo: 'influencer-platform-for-creators', pathMatch: 'full' },
  { path: 'creator-awards', component: AwardsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'thank-you', component:  ThankYouComponent},
  { path: 'influencer-terms-use', component: TermsOfUseComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'influence-for-good', component: InfluenceForGoodComponent },
  { path: 'blog/:articleId', component: ArticleComponent },
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: 'home', redirectTo: '', component: HomeComponent },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
