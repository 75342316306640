import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle("Top Influencer Marketing Agency in Dubai | Brandripplr");
    this.metaService.addTags([
      { name: 'description', content: 'Brandripplr – the best micro influencer marketing agency in Dubai that focuses on signing and managing social media influencers and creating successful campaigns.' },
      { property: 'og:title', content: 'Top Micro Influencer Marketing Agency in Dubai | Brandripplr' },
      { property: 'og:site_name', content: 'Brandripplr' },
      { property: 'og:url', content: 'https://www.brandripplr.com/' },
      { property: 'og:description', content: 'Brandripplr – the best micro influencer marketing agency in Dubai that focuses on signing and managing social media influencers and creating successful campaigns.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:image', content: 'https://ibb.co/bXLgBXN' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:domain', content: 'brandripplr.com' },
      { property: 'twitter:url', content: 'https://www.brandripplr.com/' },
      { name: 'twitter:title', content: 'Influencer Marketing Agency | BrandRipplr' },
      { name: 'twitter:description', content: 'Boost your brand with Brandripplr\'s influencer marketing services. Connect with top social media influencers and enhance your marketing strategy today.' },
      { name: 'twitter:image', content: 'https://opengraph.b-cdn.net/production/images/3b214ec5-9113-4ab6-9b5e-db6ab7dc1a4d.png?token=ZiZYGaFB8WIyRcQtVfCZo0i6koxEhSD03-aaOL5hHXk&height=132&width=600&expires=33267500174' }

    ]);
    this.loadScript();
  }
//   <script type="application/ld+json">
// {
//   "@context": "https://schema.org",
//   "@type": "Organization",
//   "name": "Brandripplr | Influencer Marketing Agency in Dubai",
//   "alternateName": "Brandripplr",
//   "url": "https://www.brandripplr.com/",
//   "logo": "https://www.brandripplr.com/assets/images/brandripplr.png",
//   "contactPoint": {
//     "@type": "ContactPoint",
//     "telephone": "+971 52 657 4408",
//     "contactType": "customer service",
//     "contactOption": "TollFree",
//     "areaServed": "AE",
//     "availableLanguage": "en"
//   },
//   "sameAs": [
//     "https://www.facebook.com/BrandRipplr/",
//     "https://twitter.com/BrandRipplr",
//     "https://www.instagram.com/brandripplr/",
//     "https://www.youtube.com/@brandripplr1446",
//     "https://www.linkedin.com/company/brandripplr",
//     "https://www.brandripplr.com/influencer-marketing-platform"
//   ]
// }
// </script>
  loadScript() {
    const head = <HTMLDivElement> document.head;
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = `
    
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Brandripplr | Influencer Marketing Agency in Dubai",
      "alternateName": "Brandripplr",
      "url": "https://www.brandripplr.com/",
      "logo": "https://www.brandripplr.com/assets/images/brandripplr.png",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+971 52 657 4408",
        "contactType": "customer service",
        "contactOption": "TollFree",
        "areaServed": "AE",
        "availableLanguage": "en"
      },
      "sameAs": [
        "https://www.facebook.com/BrandRipplr/",
        "https://twitter.com/BrandRipplr",
        "https://www.instagram.com/brandripplr/",
        "https://www.youtube.com/@brandripplr1446",
        "https://www.linkedin.com/company/brandripplr",
        "https://www.brandripplr.com/influencer-marketing-platform"
      ]
    }
    `;
    head.appendChild(script);
  }


  clients: Array<string> = [
    'assets/images/clients/nestle.png',
    'assets/images/clients/pepsico.png',
    'assets/images/clients/sta.png',
    'assets/images/clients/mars.png',
    'assets/images/clients/emirates.png',
    'assets/images/clients/spotify.png',
    'assets/images/clients/pampers.png',
    'assets/images/clients/damac.png',
    'assets/images/clients/alshaya.png',
    'assets/images/clients/hp.png'


    // 'assets/images/clients/elc.png',
    
    // 'assets/images/clients/dt.png',
    // 'assets/images/clients/porsche.png',
    // 'assets/images/clients/etisalat.png',
    
    // 'assets/images/clients/beiseirdorf.png',
        
    // 'assets/images/clients/pubg.png',
    // 'assets/images/clients/aldo.png',
    // 'assets/images/clients/reckitt.png',
    // 'assets/images/clients/snapchat.png',
    // 'assets/images/clients/disney.png',
    // 'assets/images/clients/visa.png',
    // 'assets/images/clients/americana.png',
    
    
    // 'assets/images/clients/vivo.png',


    // 'assets/images/clients/galaxy.png',
    // 'assets/images/clients/johnson_and_johnson.png',
    // 'assets/images/clients/landmark.png',
    // 'assets/images/clients/masafi.png',
    // 'assets/images/clients/nestle.png',
    
    
    // 'assets/images/clients/puma.png',
    
    // 'assets/images/clients/careem.png',
    // 'assets/images/clients/quaker.png',
    // 'assets/images/clients/zara.png',
    
    
  ];

  influencers: any = {
    entertainment: [
      {
        image: "HadeelMarei.jpg",
        name: "Hadeel Marei",
        location: "UAE",
        instagram: "https://www.instagram.com/hadeelmarei/?hl=en",
        snapchat: "https://www.snapchat.com/add/hdosh",
        youtube: "https://www.youtube.com/user/hadeelmarei",
      },
      {
        image: "AmyRoko.jpg",
        name: "Amy Roko",
        location: "KSA",
        instagram: "https://www.instagram.com/amyroko/?hl=en",
        snapchat: "https://www.snapchat.com/add/amyroko",
        youtube: "https://www.youtube.com/channel/UCQRClnaCbEpW-3-fF8aMM7A",
        tiktok: "https://www.tiktok.com/@amyroko?lang=en"
      },
      {
        image: "GhaithMarwan.jpg",
        name: "Ghaith Marwan",
        location: "UAE",
        instagram: "https://www.instagram.com/ghaith_marwan/?hl=en",
        snapchat: "https://www.snapchat.com/add/ghaithmarwan",
        youtube: "https://www.youtube.com/c/GhaithMarwan",
        tiktok: "https://www.tiktok.com/@ghaith_marwan?lang=en"
      },
      {
        image: "TheSaudiReporters.png",
        name: "The Saudi Reporters",
        location: "KSA",
        instagram: "https://www.instagram.com/saudireporters/?hl=en",
        snapchat: "https://www.snapchat.com/add/boodyzozo/",
        snapchat1: "https://www.snapchat.com/add/zozobakrtv",
        youtube: "https://www.youtube.com/user/thesaudireporters/videos",
      }
    ],
    gaming: [
      {
        image: "HessaKhattaf.jpg",
        name: "Hessa Khattaf",
        location: "Kuwait",
        instagram: "https://www.instagram.com/khattafya/?hl=en",
        snapchat: "https://www.snapchat.com/add/hessakhattafya",
        youtube: "https://www.youtube.com/c/Khattafya",
        tiktok: "https://www.tiktok.com/@hessakhattafya?lang=en"
      },
      {
        image: "Mjrmgemz.jpg",
        name: "Mjrmgemz",
        location: "KSA",
        instagram: "https://www.instagram.com/r.mjrm/?hl=en",
        snapchat: "https://www.snapchat.com/add/mjrmgems",
        youtube: "https://www.youtube.com/channel/UCesOgVkywcnbAtyNf-XZ4AA",
        tiktok: "https://www.tiktok.com/@mjrmgemz?lang=en"
      },
      {
        image: "Banderita.jpg",
        name: "Banderita",
        location: "KSA",
        instagram: "https://www.instagram.com/banderitax/?hl=en",
        snapchat: "https://www.snapchat.com/add/banderitax",
        youtube: "https://www.youtube.com/c/BanderitaX"
      },
      {
        image: "SalehAlzahrani.jpg",
        name: "Saleh Alzahrani",
        location: "KSA",
        instagram: "https://www.instagram.com/opiilz/",
        youtube: "https://www.youtube.com/c/oPiiLz"
      },
    ],
    parenting: [
      {
        image: "TheASKFamily.jpg",
        name: "The ASK Family",
        location: "UAE",
        instagram: "https://www.instagram.com/drahmadjarbou/?hl=en",
        instagram1: "https://www.instagram.com/sallyserdaneh/?hl=en",
        youtube: "https://www.youtube.com/channel/UCuwh2qFz4TAXdwoLWCKWocA",
        tiktok: "https://www.tiktok.com/@theaskfamily"
      },
      {
        image: "YasmeenDakheel.jpg",
        name: "Yasmeen Dakheel",
        location: "KSA",
        instagram: "https://www.instagram.com/yasmeendakheel/?hl=en",
        snapchat: "https://www.snapchat.com/add/yasmeenadakheel",
        tiktok: "https://www.tiktok.com/@yasmeendakheel"
      },
      {
        image: "MoshayaFamily.jpg",
        name: "Moshaya Family",
        location: "KSA",
        instagram: "https://www.instagram.com/mmoshaya/?hl=en",
        snapchat: "https://www.snapchat.com/add/mmoshaya",
        youtube: "https://www.youtube.com/c/TheMoshayaFamily"
      },
      {
        image: "AmroQudah.jpg",
        name: "Amro Qudah",
        location: "UAE",
        instagram: "https://www.instagram.com/amroqudah/?hl=en",
        snapchat: "https://www.snapchat.com/add/amroqudah",
        youtube: "https://www.youtube.com/channel/UCUV66bogHy7saqP3h6hmANQ",
        tiktok: "https://www.tiktok.com/@amroqudah?lang=en"
      }
    ],
    food: [
      {
        image: "HishamBaeshan.jpg",
        name: "Hisham Baeshen",
        location: "KSA",
        instagram: "https://www.instagram.com/misho_baeshen/?hl=en",
        youtube: "https://www.youtube.com/c/misho_baeshen",
        tiktok: "https://www.tiktok.com/@misho_baeshen?lang=en",
        snapchat: "https://www.snapchat.com/add/foody_moody"
      },
      {
        image: "HussainSallam.png",
        name: "Hussain Sallam",
        location: "KSA",
        instagram: "https://www.instagram.com/hussain.sallam/?hl=en",
        youtube: "https://www.youtube.com/c/S7Schallenges",
        tiktok: "https://www.tiktok.com/@hussain.sallam?lang=en"
      },
      {
        image: "TaimFalasi.jpg",
        name: "Taim Falasi",
        location: "UAE",
        instagram: "https://www.instagram.com/taimalfalasi/?hl=en",
        youtube: "https://www.youtube.com/user/TaimAlFalasi",
        tiktok: "https://www.tiktok.com/@taimalfalasi?lang=en",
        snapchat: "https://www.snapchat.com/add/taimalfalasi"
      },
      {
        image: "ChefShaheen.jpg",
        name: "Chef Shaheen",
        location: "Iraq",
        instagram: "https://www.instagram.com/chef.shaheen/?hl=en",
        youtube: "https://www.youtube.com/channel/UCo0m8aHuHA7pq3LCeN6l73Q",
        tiktok: "https://www.tiktok.com/@chef_shaheen?lang=en",
      }
      // {
      //   image: "AbdulrahmanBakhsh.jpg",
      //   name: "Abdulrahman Bakhsh",
      //   location: "KSA",
      //   instagram: "https://www.instagram.com/healthway_22/?hl=en",
      //   youtube: "https://www.youtube.com/channel/UCXSMfrVb0NkCbBuaWJGKcrw",
      //   tiktok: "https://www.tiktok.com/@abdulrahman.bakhsh?lang=en",
      // }
    ],
    fashion: [
      {
        image: "FasialGhazzawi.jpg",
        name: "Faisal Ghazzawi",
        location: "KSA",
        instagram: "https://www.instagram.com/falghazzawi/?hl=en",
        snapchat: "https://www.snapchat.com/add/falghazzawi",
        tiktok: "https://www.tiktok.com/@falghazzawi?lang=en",
      },
      {
        image: "DimaSheikhly.jpg",
        name: "Dima Sheikhly",
        location: "UAE",
        youtube: "https://www.youtube.com/c/DimaAlSheikhly/videos",
        instagram: "https://www.instagram.com/dimasheikhly/?hl=en",
        snapchat: "https://www.snapchat.com/add/dimaalsheikhly",
        tiktok: "https://www.tiktok.com/@dimaalsheikhly?lang=en",
      },
      {
        image: "HadiaGhaleb.jpg",
        name: "Hadia Ghaleb",
        location: "UAE",
        instagram: "https://www.instagram.com/hadiaghaleb/?hl=en",
        snapchat: "https://www.snapchat.com/add/HadiaGhaleb",
        tiktok: "https://www.tiktok.com/@hadiaghaleb?lang=en",
      },
      {
        image: "OlaFarhat.jpg",
        name: "Ola Farhat",
        location: "UAE",
        instagram: "https://www.instagram.com/olafarahat/?hl=en",
        snapchat: "https://www.snapchat.com/add/olafarahat",
        tiktok: "https://www.tiktok.com/@olafarahat?lang=en",
        youtube: "https://www.youtube.com/channel/UC88p9dkTUB0E4tXo0Hr-dUQ"
      }

    ],
    beauty: [
      {
        image: "MayaAhmad.jpg",
        name: "Maya Ahmad",
        location: "UAE",
        instagram: "https://www.instagram.com/themayaahmad/?hl=en",
        tiktok: "https://www.tiktok.com/@themayaahmad?lang=en",
        youtube: "https://www.youtube.com/themayaahmad"
      },
      {
        image: "LujainGlam.jpg",
        name: "Lujain Glam",
        location: "Kuwait",
        instagram: "https://www.instagram.com/lujainglam/?hl=en",
        tiktok: "https://www.tiktok.com/@lujainglam",
        youtube: "https://www.youtube.com/c/LUJAINGLAM/featured",
        snapchat: "https://www.snapchat.com/add/lujainah90"
      },
      {
        image: "YaraAlnamlah.jpg",
        name: "Yara Alnamlah",
        location: "KSA",
        instagram: "https://www.instagram.com/yaralnamlah/?igshid=18y7aaxxy7huk",
        snapchat: "https://www.snapchat.com/add/Yara.makeup"
      },
      {
        image: "LamaAlakeel.jpg",
        name: "Lama Alakeel",
        location: "KSA",
        instagram: "https://www.instagram.com/lama.alakeel/?hl=en",
        snapchat: "https://www.snapchat.com/add/lama.alakeel",
        tiktok: "https://www.tiktok.com/@lamaalakeel"
      },
    ],
    lifestyle: [
      {
        image: "AnasalaFamily.jpg",
        name: "Anasala Family",
        location: "UAE",
        instagram: "https://www.instagram.com/asala/",
        instagram1: "https://www.instagram.com/anas/",
        snapchat: "https://www.snapchat.com/add/anasmarwah",
        youtube: "https://www.youtube.com/channel/UCqJNTGwKmgMCwfYDbnz514Q"
      },
      {
        image: "NoorStars.jpg",
        name: "Noor Stars",
        location: "UAE",
        instagram: "https://www.instagram.com/noorstars/",
        snapchat: "https://www.snapchat.com/add/noorstars21",
        youtube: "https://www.youtube.com/c/noorstars",
        tiktok: "https://www.tiktok.com/@noorstars?lang=en"
      },
      {
        image: "AhmedAlNasheet.jpg",
        name: "Ahmed Al Nasheet",
        location: "KSA",
        instagram: "https://www.instagram.com/dvlzgame/?hl=en",
        snapchat: "https://www.snapchat.com/add/dvlzgame",
        youtube: "https://www.youtube.com/channel/UCKqGZV0VV9g06kAYXAiacAQ",
        tiktok: "https://www.tiktok.com/@dvlzgame?lang=en"
      },
      {
        image: "Nanoosh.jpg",
        name: "Nanoosh",
        location: "KSA",
        instagram: "https://www.instagram.com/nanoosh771/?hl=en",
        snapchat: "https://www.snapchat.com/add/nanoosh771",
        youtube: "https://www.youtube.com/channel/UC0t7j_GHhhYKrJleTtW4_jw",
        tiktok: "https://www.tiktok.com/@ahmed.jar?lang=en"
      },
    ],
    fitness: [
      {
        image: "HayaSawan.jpg",
        name: "Haya Sawan",
        location: "KSA",
        instagram: "https://www.instagram.com/hayasawan_official/?hl=en",
        snapchat: "https://story.snapchat.com/@haya_sawan"
      },
      {
        image: "WalidYari.jpg",
        name: "Walid Yari",
        location: "UAE",
        youtube: "https://www.youtube.com/c/WalidYari/videos",
        tiktok: "https://www.tiktok.com/@walidyari?lang=en",
        instagram: "https://www.instagram.com/walidyari/"
      },
      {
        image: "RabihElTurk.jpg",
        name: "Rabih El Turk",
        location: "UAE",
        instagram: "https://www.instagram.com/rabihelturk/"
      },
      {
        image: "BaraaElSabbagh.jpeg",
        name: "Baraa El Sabbagh",
        location: "UAE",
        youtube: "https://www.youtube.com/c/BaraaElsabbagh",
        tiktok: "https://www.tiktok.com/@baraaelsabbagh?lang=en",
        instagram: "https://www.instagram.com/baraaelsabbagh/?igshid=YmMyMTA2M2Y%3D"
      }
    ],
    tech: [
      {
        image: "FaisalAlSaif.jpg",
        name: "Faisal AlSaif",
        location: "KSA",
        snapchat: "https://www.snapchat.com/add/faisaltechpills",
        instagram: "https://www.instagram.com/faisalaalsaif/?hl=en"
      },
      {
        image: "Abdullah.png",
        name: "Abdullah",
        location: "KSA",
        snapchat: "https://www.snapchat.com/add/iapp_free"
      },
      {
        image: "Techmab.jpg",
        name: "Techmab",
        location: "KSA",
        youtube: "https://www.youtube.com/c/techmab",
        instagram: "https://www.instagram.com/techmab/?hl=en"
      },
      {
        image: "7alsabe.png",
        name: "7alsabe",
        location: "KSA",
        snapchat: "https://www.snapchat.com/add/alsabe3",
        instagram: "https://www.instagram.com/7alsabe/?hl=en"
      }
    ],
  }

  testimonials: Array<any> = [
    {
      name: "Roberta El Amil",
      company: "Estee Lauder Companies",
      testimonial: "Comprehensive campaign management services from A-Z including influencer casting and vetting, negotiations, campaign strategy, insightful reports and an overall influencer management – extremely reliable team!"
    },
    {
      name: "Taha Rehman",
      company: "Reckitt",
      testimonial: "Brand Ripplr provides access to a large network of influencers with an advanced dashboard that delivers insightful analytics on influencers to help us make educated decisions on how to plan our campaigns effectively."
    },
    {
      name: "Lana Alkilani",
      company: "PepsiCo",
      testimonial: "It meets all our influencer campaign needs, with a highly knowledgeable and resourceful team members who provide comprehensive support to ensure our campaigns are smooth and optimised from start to finish."
    }
  ];

  influencersContents: Array<any> = [
    {
      videoName: "12"
    },
    {
      videoName: "13"
    },
    {
      videoName: "14"
    },
    {
      videoName: "15"
    },
    {
      videoName: "16"
    },
    {
      videoName: "17"
    },
    {
      videoName: "18"
    },
    {
      videoName: "19"
    },
    {
      videoName: "20"
    },
    {
      videoName: "21"
    }
  ];

  activeInfluencerTab:string = 'beauty';

  changeInfluencerTab(tab:any) {
    this.activeInfluencerTab = tab
  }

  selectedInfluencersContents: Array<any> = [];

  isPlaying: Boolean = false;
  isPlayed: Boolean = false;

  playPause() {
    var myVideo: any = document.getElementById("my_video_1");
    if (myVideo.paused) {
      this.isPlaying = true;
      this.isPlayed = true;
      myVideo.play();
    }
    else {
      this.isPlaying = false;
      myVideo.pause();
    }
  }

  ngOnInit(): void {
    var influencersContentsLen = this.influencersContents.length;
    for (var i = 0; i < 3; i++) {
      var randomIndex = Math.floor(Math.random() * influencersContentsLen);
      this.selectedInfluencersContents.push(this.influencersContents[randomIndex]);
      this.influencersContents.splice(randomIndex, 1);
      influencersContentsLen -= 1;
    }
  }
}
