import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-thank-you',
  templateUrl: './contact-thank-you.component.html',
  styleUrls: ['./contact-thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {


  
   constructor(private titleService: Title) {
    this.titleService.setTitle("Thank you");
    // this.metaService.updateTag({ name: 'description', content: 'Have questions or need support? Reach out to Brandripplr for inquiries about influencer marketing and our services. We’re here to help.' });
   }

  ngOnInit(): void {
  }
}
